import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PrivacyModePipe } from 'src/app/_helpers/privacy-mode.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardPrecatorioComponent } from 'src/app/module-client/client-investment/card-precatorio/card-precatorio.component';
import { MaterialModule } from './material.module';
import { RouterModule } from '@angular/router';
import { CardInvestmentTokenComponent } from 'src/app/module-client/client-investment-token/card-investment-token/card-investment-token.component';
import { PdfViewComponent } from '../components/pdf-view/pdf-view.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { FooterLpComponent } from '../components/footer-lp/footer-lp.component';
import { AcordFilterComponent } from '../components/acord-filter/acord-filter.component';

@NgModule({
  declarations: [
    PrivacyModePipe,
    CardPrecatorioComponent,
    CardInvestmentTokenComponent,
    PdfViewComponent,
    NavbarComponent,
    FooterLpComponent,
    AcordFilterComponent
  ],
  imports: [CommonModule, ReactiveFormsModule, NgxMaskDirective, NgxMaskPipe, MaterialModule, RouterModule, PdfViewerModule],
  exports: [
    ReactiveFormsModule,
    PrivacyModePipe,
    NgxMaskDirective,
    NgxMaskPipe,
    CardPrecatorioComponent,
    CardInvestmentTokenComponent,
    PdfViewComponent,
    PdfViewerModule,
    NavbarComponent,
    FooterLpComponent,
    AcordFilterComponent
  ],
  providers: [provideNgxMask()],
})
export class SharedModule {}
