import { ActivatedRoute, Router } from '@angular/router';
import { Component, OnInit } from '@angular/core';
import { EAlertIcons, EAlertTypes } from 'src/app/models/alert-icons.enum';

import { DialogPagamentoCartaoComponent } from '../shared/dialogs/dialog-pagamento-cartao/dialog-pagamento-cartao.component';
import { DialogPagamentoPixComponent } from '../shared/dialogs/dialog-pagamento-pix/dialog-pagamento-pix.component';
import { DomSanitizer } from '@angular/platform-browser';
import { INftDetalhePedido } from '../model/nft-pedido.interface';
import { INftDetalheTransacao } from '../model/nft-detalhe-transacao.interface';
import { Location } from '@angular/common';
import { MatDialog } from '@angular/material/dialog';
import { MatIconRegistry } from '@angular/material/icon';
import { NftService } from 'src/app/services/nft.service';
import { UtilService } from 'src/app/_helpers/util.service';

export interface IMercadoPagoTiposPagamento {
  id: string;
  texto: string;
  selecionado?: boolean;
}

@Component({
  selector: 'app-client-ordering-details',
  templateUrl: './client-ordering-details.component.html',
  styleUrls: ['./client-ordering-details.component.scss'],
})
export class ClientOrderingDetailsComponent implements OnInit {
  idPedido: string | null = null;
  isVisible = false;
  hasPaymentError = false;
  linkPagamento: string | null = null;
  meioPagamentoSelecionado: IMercadoPagoTiposPagamento | null = null;
  opcaoPagamento: string | null = null;
  pedidoDetalhe: INftDetalhePedido | null = null;
  tempoRestante: string | null = null;
  transacaoDetalhe: INftDetalheTransacao[] | null = null;
  tiposPagamento: IMercadoPagoTiposPagamento[] = [
    { id: 'pix', texto: 'Pix', selecionado: false },
    { id: 'cartao', texto: 'Cartão de Crédito', selecionado: false },
  ];
  quantidadeTokens = 1;

  protected alertErrorConfig = {
    type: EAlertTypes.error,
    icon: EAlertIcons.error,
    title: `Pagamento expirado`,
    content: `O tempo limite para confirmar a transição expirou. Refaça o processo.`,
  };

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly route: ActivatedRoute,
    private readonly location: Location,
    private readonly nftService: NftService,
    private readonly utilService: UtilService,
    private readonly router: Router,
    private readonly dialog: MatDialog
  ) {
    this.idPedido = this.route.snapshot.params['id_pedido'];

    this.matIconRegistry.addSvgIcon(
      'visibility',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/visibility.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'visibility_off',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/visibility_off.svg'
      )
    );

    this.matIconRegistry.addSvgIcon(
      'check_circle',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/check_circle.svg'
      )
    );
  }

  ngOnInit(): void {
    if (this.idPedido) {
      this.getDadosPedido(this.idPedido);
      this.carregarDadosCarrinho(this.idPedido);
    }
  }

  /**
   * Toggles visibility
   */
  protected toggleVisibility(): void {
    this.isVisible = !this.isVisible;
  }

  /**
   * Ele itera sobre o array tiposPagamento para encontrar a opção que já está selecionada.
   * Se encontrar alguma opção já selecionada, desmarca-a
   * @param option
   * @returns selected
   */
  protected isSelected(option: any): void {
    const optionSelected = this.tiposPagamento.find(
      (option) => option.selecionado === true
    );

    if (optionSelected === option) {
      // A opção já está selecionada, não faz nada.
      return;
    }

    if (optionSelected) {
      optionSelected.selecionado = false;
    }

    option.selecionado = true;
  }

  /**
   * Go back
   */
  protected goBack(): void {
    this.location.back();
  }

  /**
   * Gets dados pedido
   * @param idPedido
   */
  protected getDadosPedido(idPedido: string): void {
    this.nftService.getDadosPedido(idPedido).subscribe({
      next: (response: any) => {
        const status = response.body['status'];

        // Monta os detalhes do pedido
        this.pedidoDetalhe = response.body;
        this.quantidadeTokens = this.pedidoDetalhe.qtd_cesta 
        if (status === 'approved') {
          this.router.navigateByUrl(
            '/client/transacao-detalhe/' + this.idPedido
          );
          return;
        }

        if (status === 'expirado') {
          this.hasPaymentError = true;
          return;
        }

        // Monta contador regressivo do carrinho
        const segundosRestantes = response.body['secs_restante'];
        const totalSegundos = segundosRestantes / 60;
        if (segundosRestantes) {
          this._countdown(totalSegundos);
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  /**
   * Carregars dados carrinho
   * @param idPedido
   */
  protected carregarDadosCarrinho(idPedido: string): void {
    this.nftService.getDetalhesItensPedidoTransacaoById(idPedido).subscribe({
      next: (response: any) => {
        if (response.body) {
          this.transacaoDetalhe = response.body[0];
          // this.quantidadeTokens = response.body.length;
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  /**
   * Criars pagamento
   */
  protected criarPagamento(): void {
    if (this.meioPagamentoSelecionado) {
      const pagamentoSelecionado = this.meioPagamentoSelecionado['id'];
      switch (pagamentoSelecionado) {
        case 'cartao':
          this.criarPagamentoCartao();
          break;

        default:
          const data = {
            payment_method_id: pagamentoSelecionado,
            id_pedido: this.idPedido,
            amount: this.pedidoDetalhe.valor_transacao,
          };
          this.criarPagamentoDefault(data);
          break;
      }
    }
  }

  /**
   * Criars pagamento cartao
   */
  protected criarPagamentoCartao(): void {
    const dialogRef = this.dialog.open(DialogPagamentoCartaoComponent, {
      width: '860px',
      data: {
        pedidoDetalhe: this.pedidoDetalhe,
        idPedido: this.idPedido,
      },
    });

    dialogRef.afterClosed().subscribe((result) => {
      // console.log('DialogPagamentoCartaoComponent afterClosed', result);
      // if (result) {
      //   window.location.reload();
      // }
    });
  }

  /**
   * Criars pagamento default
   * @param data
   */
  protected criarPagamentoDefault(data: any): void {
    this.nftService.criarPagamento(data).subscribe({
      next: (response: any) => {
        if (response && response.body) {
          this.linkPagamento = response.body['qr_code'];
          const valorPagamento = response.body['valor_transacao'];

          const dialogRef = this.dialog.open(DialogPagamentoPixComponent, {
            width: '860px',
            data: {
              linkPagamento: this.linkPagamento,
              valorPagamento: valorPagamento,
            },
          });

          dialogRef.afterClosed().subscribe((result) => {
            // if (result) {
            //   window.location.reload();
            // }
          });
        }
      },
      error: (error) => {
        this.utilService.floaterNotificationError(
          error.error || 'Erro ao gerar pagamento. Por favor, tente novamente.'
        );
      },
    });
  }

  /**
   * Countdowns client ordering details component
   * @param totalSeconds
   */
  private _countdown(totalSeconds: number): void {
    let currentSeconds = totalSeconds;

    const interval = setInterval(() => {
      currentSeconds--;

      if (currentSeconds <= 0) {
        clearInterval(interval);
        // console.log('FIM!');
      } else {
        this.tempoRestante = this._formatSeconds(currentSeconds);
      }
    }, 1000);
  }

  /**
   * Formats seconds
   * @param totalSeconds
   * @returns seconds
   */
  private _formatSeconds(totalSeconds: number): string {
    const hours = Math.floor(totalSeconds / 3600);
    totalSeconds = totalSeconds % 3600;

    const minutes = Math.floor(totalSeconds / 60);
    const seconds = Math.floor(totalSeconds % 60);

    return `${this._pad(hours)}:${this._pad(minutes)}:${this._pad(seconds)}`;
  }

  /**
   * Pads client ordering details component
   * @param num
   * @returns pad
   */
  private _pad(num: number): string {
    return String(num).padStart(2, '0');
  }
}
