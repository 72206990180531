import { Component } from '@angular/core';

@Component({
  selector: 'app-aloc-pessoa',
  standalone: true,
  imports: [],
  templateUrl: './aloc-pessoa.component.html',
  styleUrl: './aloc-pessoa.component.scss'
})
export class AlocPessoaComponent {

}
