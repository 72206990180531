import { Component } from '@angular/core';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-client-wallet',
  templateUrl: './client-wallet.component.html',
  styleUrls: ['./client-wallet.component.scss']
})
export class ClientWalletComponent {
dataSourceProjects: MatTableDataSource<any> = new MatTableDataSource();

projectsColunas: string[] = [
  'data_pedido_criacao',
  'cliente',
  'projeto',
  'status',
  'detalhes',
];


ngOnInit(): void {
  // this.getBalance();
  // this.getMeusNftByUserAgrupado();
  this.setMocks();
  // this.getPedidosUsuario();
}


setMocks(): void {
  const health = [
    {
      "data_pedido_criacao": "2024-07-13 14:17:09",
      "id_pedido": 788,
      "operacao": "Consulta",
      "status": "expired"
    },
    {
      "data_pedido_criacao": "2024-07-13 14:17:36",
      "id_pedido": 789,
      "operacao": "Consulta",
      "status": "approved"
    },
    {
      "data_pedido_criacao": "2024-07-13 14:18:12",
      "id_pedido": 790,
      "operacao": "Consulta",
      "status": "approved"
    },
    {
      "data_pedido_criacao": "2024-07-13 14:18:45",
      "id_pedido": 791,
      "operacao": "Operação",
      "status": "approved"
    },
    {
      "data_pedido_criacao": "2024-07-13 14:19:16",
      "id_pedido": 792,
      "operacao": "Consulta",
      "status": "approved"
    }
  ];

  const projects = [
    {
        "data_pedido_criacao": "2024-07-13 14:17:09",
        "id_pedido": 1081,
        "cliente": "A&M",
        "projeto": "DDO",
        "status": "pending"
    },
    {
        "data_pedido_criacao": "2024-07-13 14:17:36",
        "id_pedido": 1081,
        "cliente": "A&M",
        "projeto": "Logística",
        "status": "pending"
    },
    {
        "data_pedido_criacao": "2024-07-13 14:18:12",
        "id_pedido": 1081,
        "cliente": "Ambev",
        "projeto": "DDO",
        "status": "approved"
    },
    {
        "data_pedido_criacao": "2024-07-13 14:18:45",
        "id_pedido": 1081,
        "cliente": "A&M",
        "projeto": "Redução",
        "status": "approved"
    },
    {
        "data_pedido_criacao": "2024-07-13 14:19:16",
        "id_pedido": 1081,
        "cliente": "A&M",
        "projeto": "Análise",
        "status": "expired"
    }
]

  // this.dataSourceLoyalty = new MatTableDataSource();
  // this.dataSourceHealth = new MatTableDataSource(health);
  this.dataSourceProjects = new MatTableDataSource(projects);

}


}
