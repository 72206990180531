import { Injectable } from '@angular/core';

@Injectable({
    providedIn: 'root',
})
export class CpfService {

    validateCpf(cpf: string): boolean {
        if (!cpf) return false;

        const cpfDigits = cpf.replace(/\D/g, '');

        if (cpfDigits.length !== 11 || /^(\d)\1+$/.test(cpfDigits)) return false;

        const validateDigit = (digits: string, factor: number): number => {
            let total = 0;
            for (const digit of digits) {
                if (factor > 1) total += parseInt(digit, 10) * factor--;
            }
            const rest = total % 11;
            return rest < 2 ? 0 : 11 - rest;
        };

        const digit1 = validateDigit(cpfDigits.slice(0, 9), 10);
        const digit2 = validateDigit(cpfDigits.slice(0, 10), 11);

        return (
            digit1 === parseInt(cpfDigits.charAt(9), 10) &&
            digit2 === parseInt(cpfDigits.charAt(10), 10)
        );
    }
}
