<div class="container">

  <div class="title mt-4">
    <h1 class="text-main">Contas conectadas</h1>
    <p class="tab-subtitle">
      Aqui fica a sua chave Pix para o recebimento de resgate
    </p>
  </div>

  <ng-container [formGroup]="formPix">

    <div class="row mt-5">
      <div class="col-md-4">
          <mat-form-field appearance="outline" class="w-100">
            <mat-label>Tipo de Chave</mat-label>
            <mat-select formControlName="conta_pix_tipo" (selectionChange)="onKeyTypeChange()">
              <mat-option *ngFor="let type of pixTypes" [value]="type.value" >
                {{ type.viewValue }}
              </mat-option>
            </mat-select>
          </mat-form-field>
      </div>

      <div class="col-md-8">
        <mat-form-field appearance="outline" class="form-input form-input--full">
          <mat-label>Chave Pix</mat-label>
          <input matInput formControlName="conta_pix_chave" [mask]="mask" required />
        </mat-form-field>
      </div>
    </div>

    <p>Toda conta cadastrada deve ser exclusivamente de sua propriedade, nunca de terceiros.</p>

    <div class="d-flex mt-4">
      <button type="submit" mat-flat-button color="primary" class="btn-large text-uppercase ms-auto" (click)="saveDataPix(isUpdating)">
        {{ buttonLabel }}
      </button>
    </div>
    

  </ng-container>

</div>
