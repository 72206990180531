import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-card-project',
  standalone: false,
  templateUrl: './card-project.component.html',
  styleUrl: './card-project.component.scss'
})
export class CardProjectComponent {

  @Input() isCurrent: number = 0;
  @Input() logoUrl: string = '';
  @Input() projectName: string = '';
  @Input() projectCode: string = '';
  @Input() startDate: string = '';
  @Input() endDate: string = '';
  @Input() status: string = '';
  @Input() sector: string = '';
  @Input() product: string = '';
  @Input() color: string = '';
  @Input() canEdit: boolean = false;
  @Input() project!: any;
  @Output() editClicked = new EventEmitter<any>();
  @Output() deleteClicked = new EventEmitter<any>();

  onEditClick(): void {
    this.editClicked.emit(this.project);
  }

  onDeleteClick(): void {
    this.deleteClicked.emit(this.project);
  }

}
