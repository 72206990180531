import {
  AbstractControl,
  FormBuilder,
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from '@angular/forms';
import { ConfirmPasswordValidator } from 'src/app/shared/validators/password-matcher';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilService } from 'src/app/_helpers/util.service';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { CpfService } from 'src/app/services/cpf.service';
import { cpfValidator } from 'src/app/shared/validators/cpf.validator';

@Component({
  selector: 'app-register',
  templateUrl: './register.component.html',
  styleUrls: ['./register.component.scss'],
})
export class RegisterComponent {

  protected isVisible = false;

  protected registerForm = this.formBuilder.group(
    { tipo_usuario: [null],
      user_name: [null, [Validators.required]],
      user_cpf_cnpj: [null, [Validators.required, cpfValidator(this.cpfService)]],
      user_email: [null, [Validators.required, Validators.email]],
      user_celular: [null, [Validators.required]],
      user_pwd: [
        null,
        [
          Validators.required,
          Validators.minLength(9),
          Validators.maxLength(20),
          Validators.pattern(/^(?=.*[A-Z])(?=.*[a-z])(?=.*[\W_]).+/),
        ],
      ],
      user_pwd_confirma: [null, [Validators.required]],
    },
    {
      validator: ConfirmPasswordValidator('user_pwd', 'user_pwd_confirma'),
    }
  );

  constructor(
    private readonly formBuilder: FormBuilder,
    private readonly authService: AuthenticationService,
    private readonly utilService: UtilService,
    private readonly router: Router,
    private readonly cpfService: CpfService,
  ) {}

  protected toggleVisibility(): void {
    this.isVisible = !this.isVisible;
  }

  protected get userEmail(): AbstractControl {
    return this.registerForm.controls['user_email'];
  }
  /**
   * Passo 1: Realiza o cadastro pelo service (marketplace_nft/efetuar_cadastro_usuario/)
   * Passo 2: Verifica o e-mail cadastrado a partir do e-mail preenchido no passo 1 (marketplace_nft/verificar_email_cadastrado)
   * Passo 3: Chama o serviço de obter o codigo de verificação depois que verificou o e-mail cadastrado no passo 1 e 2 (marketplace_nft/obter_codigo_verificacao/)
   */
  protected register(): void {
    if (this.registerForm.invalid) return;

    const payload = {
      user: this.registerForm.value
    };

    console.log('pay', payload)

    // Realiza o cadastro
    this.authService.register(payload).subscribe({
      next: (response: any) => {
        this.utilService.floaterNotificationSuccess(
          'Usuário criado com sucesso, confirme o código para ativar sua conta.'
        );
        let params = {
          user_email: this.userEmail.value,
        };
        this.router.navigate(['/auth/cadastro/confirmacao'], {
          state: { params },
        });
      },
      error: (error) => {
        console.error('Register error: ', error);
        if (error && error.error) {
          console.error(error);
        }
      },
    });
  }

}
