
<mat-form-field class="fcolor pl-20 pr-20">
    <input autocomplete="off"   [(ngModel)]="filterValue"  (keydown.enter)="keyEnter()"  matInput type="search" placeholder="{{placeholder}}" class="search-input">
    <!-- (keydown)="keyDown()" -->
    <!-- <mat-icon matPrefix>search</mat-icon> -->
    <button *ngIf="filterValue" matSuffix mat-icon-button aria-label="Limpar" (click)="limparCampo()">
        <mat-icon>close</mat-icon>
    </button>
    <button  (click)="keyEnter()" class="tableHeadBtn" matSuffix mat-icon-button aria-label="Pesquisar">
        <mat-icon class="ajust-font-icon ">search</mat-icon>
    </button>
</mat-form-field>