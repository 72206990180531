import { Component, ElementRef, HostListener } from '@angular/core';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { Router } from '@angular/router';
import { navItems } from './nav-items';

@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent {

  user: any | null = null;
  firstNameUser: string = '';

  constructor(
    private router: Router,
    private elementRef: ElementRef,
    private authService: AuthenticationService,
  ) {}

  isMenuOpen: boolean = false;
  breakpoint: number = 1200;
  isLoggedIn: boolean = false;
  navLinks = navItems;

  ngOnInit(): void {
    this.isLoggedIn = this.authService.isLoggedIn();
    this.user = this.authService.getUserData();
    this.firstNameUser = this.getFirstName(this.user.user_name);
  }

  toggleMenu(): void {
    this.isMenuOpen = !this.isMenuOpen;
  }

  goTo(route: string): void {
    this.router.navigate([route]);
  }

  getFirstName(name: string): string {
    return name.split(' ')[0];
  }

  logout(event: Event): void {
    event.preventDefault();
    this.authService.logout().subscribe(() => {});
  }

  // Fecha em desktop:
  @HostListener('window:resize', ['$event'])
  onResize(event: Event) {
    const windowWidth = (event.target as Window).innerWidth;
    if (windowWidth > this.breakpoint) this.isMenuOpen = false;
  }

  // Fecha ao clicar fora do menu mobile:
  @HostListener('document:click', ['$event'])
  onClick(event: MouseEvent) {
    const clickedInside = this.elementRef.nativeElement.contains(event.target);
    if (!clickedInside && this.isMenuOpen) this.isMenuOpen = false;
  }
}
