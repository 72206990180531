import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';

@Component({
  selector: 'app-acord-filter',
  templateUrl: './acord-filter.component.html',
  styleUrls: ['./acord-filter.component.sass']
})
export class AcordFilterComponent implements OnInit {

  constructor() { }
  filterValue=""
  @Input()
  placeholder = "Pesquisar"
  @Output()
  onEnterr: EventEmitter<string> = new EventEmitter<string>();
  
  limparCampo() {
    this.filterValue = "";
    this.keyEnter()
   }

  keyEnter() {
    this.onEnterr.emit(this.filterValue)
  }

  ngOnInit(): void {
  }

  

}
