<div class="auth-container">
  <div class="auth-logo">
    <img src="assets/images/aem_logo.png"
      width="200"
      alt="Logotipo da A&M" />
  </div>
  <span class="auth-icon">
    <img src="assets/emojis/locked.png"
      alt="Emoji de cadeado" />
  </span>
  <ng-container *ngIf="!isSubmitted; else confirmationCode">
    <h1 class="auth-title">Esqueci minha senha</h1>
    <p class="auth-subtitle">
      Digite seu e-mail e enviaremos as instruções para redefinir sua senha
    </p>
    <div class="auth-form">
      <form [formGroup]="resetPasswordForm"
        (ngSubmit)="resetPassword()">
        <div class="auth-form-field">
          <mat-form-field appearance="outline">
            <input placeholder="E-mail"
              matInput
              type="email"
              formControlName="email"
              name="email"
              autocomplete="off"
              required />
            <mat-error *ngIf="resetPasswordForm.get('email')?.hasError('required')">Campo obrigatório</mat-error>
            <mat-error *ngIf="resetPasswordForm.get('email')?.hasError('email')">E-mail inválido</mat-error>
          </mat-form-field>
        </div>

        <div class="auth-form-actions">
          <button mat-flat-button
            class="btn-primary btn-large btn-fluid text-uppercase mt-15 mb-25">
            Redefinir senha
          </button>
        </div>
        <div class="auth-form-actions auth-form-actions--centered">
          <a class="btn-link-primary"
            style="display: flex; align-items: center;"
            color="primary"
            routerLink="/auth/login">
            <mat-icon class="btn-icon"
              color="primary"
              fontIcon="arrow_back_ios"></mat-icon>
            Voltar para acesso
          </a>
        </div>
      </form>
    </div>
  </ng-container>
</div>

<ng-template #confirmationCode>
  <h1 class="auth-title">Enviamos um código para o seu e-mail</h1>
  <p class="auth-subtitle">
    Digite o código que você acabou de receber no seu e-mail para confirmar
    a atualização de senha (verifique também a sua caixa de SPAM):
  </p>
  <div class="auth-form">
    <form [formGroup]="formVerification"
      (ngSubmit)="validateCode()">
      <div class="auth-form-field">
        <mat-form-field appearance="outline">
          <input matInput
            type="tel"
            mask="000000"
            formControlName="verification_code"
            name="verification_code"
            required />
          <mat-error *ngIf="formVerification.get('verification_code')?.hasError('required')">Campo
            obrigatório</mat-error>
        </mat-form-field>
      </div>

      <div class="auth-form-actions">
        <button mat-flat-button
          color="primary"
          class="btn-large btn-fluid text-uppercase mt-15 mb-25">
          Enviar
        </button>
      </div>
      <div class="auth-form-actions auth-form-actions--centered">
        <a class="btn-link-primary"
          color="primary"
          (click)="isSubmitted = false">
          <mat-icon class="btn-icon"
            color="primary"
            fontIcon="arrow_back_ios"></mat-icon>
          Voltar etapa
        </a>
      </div>
    </form>
  </div>
</ng-template>