<nav class="fixed-top py-3">
    <div class="container d-flex justify-content-between align-items-center">
        <h1 class="fs-4" style="margin:0px">
            <a [routerLink]="'/'">
                <img src="assets/images/aem_performance.png" class="logo-img" style="padding: 0 !important" alt="Logotipo da A&M">
            </a>
        </h1>

        <div class="d-flex gap-4 align-items-center d-none d-xl-flex">
            <a *ngFor="let link of navLinks; let i = index" [routerLink]="link.routerLink"
            class="nav-link"  [ngClass]="{ 'me-0': i === navLinks.length - 1 }">{{ link.label }}</a>
        </div>
        
        <div class="d-flex gap-3 align-items-center">
            <!-- <div class="d-flex gap-4 align-items-center d-none d-xl-flex">
                <a *ngFor="let link of navLinks; let i = index" [routerLink]="link.routerLink"
                class="nav-link"  [ngClass]="{ 'me-0': i === navLinks.length - 1 }">{{ link.label }}</a>
            </div> -->
            
            <div *ngIf="isLoggedIn" class="d-flex gap-3 align-items-center">
                <p class="user-data mb-0">Olá, 
                    <a class="text-decoration-none fw-bold" [routerLink]="'/client/meus-dados'" aria-label="Acessa Meus Dados">
                        <span class="text-alternative-dark">{{ firstNameUser }}</span>
                    </a>
                </p>
                
            </div>
            
            <button *ngIf="!isLoggedIn" mat-raised-button class="mat-raised-button mat-button-base mat-primary" color="main" (click)="goTo('/auth/login')">Entrar</button>
            <button *ngIf="isLoggedIn" mat-raised-button class="mat-raised-button mat-button-base mat-primary" style="background-color: #0085ca; color: #f0ffff" (click)="goTo('/meu-perfil')">Meu perfil</button>
            <a href="" (click)="logout($event)" class="text-main text-decoration-none logout d-flex align-items-center">Sair</a>
            <button class="hamburger" (click)="toggleMenu()">
                &#9776;
            </button>
        </div>
    </div>

    <div class="mobile-menu" *ngIf="isMenuOpen">
        <a *ngFor="let link of navLinks" [routerLink]="link.routerLink" class="nav-link">{{ link.label }}</a>
        <a *ngIf="isMenuOpen && isLoggedIn" href="javascript:" (click)="logout($event)" class="text-main text-decoration-none color: #ffff">Sair</a>
    </div>
</nav>
