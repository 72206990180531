<section class="app-content-wrapper container">
  <header class="transaction-header">
    <a class="btn btn-link btn-default" routerLink="/client/carteira">
      <mat-icon fontIcon="arrow_back_alt"></mat-icon>
      Voltar
    </a>
  </header>

  <section class="transaction-section">
    <div class="transaction-summary">
      <img src="assets/images/aem_logo.png" alt="Logotipo da A&M" width="155" />
      <!-- <button mat-stroked-button color="primary" (click)="('TODO')">Exportar PDF</button> -->
      <ng-container *ngIf="pedidoDetalhes">
        <button *ngIf="pedidoDetalhes.status === 'pending'" mat-stroked-button color="primary" [routerLink]="
            '/client/token-detalhe-pedido/' + pedidoDetalhes.id_pedido
          ">
          Realizar pagamento
        </button>

        <button *ngIf="pedidoDetalhes.status === 'aprovado'" mat-stroked-button color="primary" (click)="('TODO')">
          Exportar PDF
        </button>
        
      </ng-container>
    </div>

    <ng-container *ngIf="pedidoDetalhes">
      <!-- <div class="transaction-summary">
        <span class="row-badge" [class.row-badge--type-1]="item.tipo_operacao === 'Investimento'"
          [class.row-badge--type-2]="item.tipo_operacao === 'Transferência'"
          [class.row-badge--type-3]="item.tipo_operacao === 'Saque'">
          {{ item.tipo_operacao }}
          <ng-container [ngSwitch]="item.tipo_operacao">
            <mat-icon *ngSwitchCase="'Investimento'">monetization_on</mat-icon>
            <mat-icon *ngSwitchCase="'Transferência'">campaign</mat-icon>
            <mat-icon *ngSwitchCase="'Saque'">arrow_downward</mat-icon>
          </ng-container>
        </span>

        <span class="row-status" [class.row-status--warning]="pedidoDetalhes.status === 'pending'"
          [class.row-status--error]="pedidoDetalhes.status === 'expirado'">
          {{ pedidoStatusMap[pedidoDetalhes.status] || "" }}
        </span>
      </div> -->

      <!-- <div class="transaction-summary">
        <h2 class="transaction-title">
          Transação no valor de
          <span>{{ pedidoDetalhes?.valor_transacao | currency }}</span>
        </h2>

        <div class="transaction-date">
          Realizada em
          <time>{{
            pedidoDetalhes.data_pedido_criacao | date : "dd/MM/YYYY HH:mm"
            }}</time>
        </div>
      </div> -->
    </ng-container>
  </section>

  <mat-divider></mat-divider>

  <section class="transaction-section" *ngIf="pedidoDetalhes && transacaoDetalhes">
    <h1 class="transaction-title mb-4">Detalhes </h1>
    <div class="col-6" style="max-width: 300px;">
      <button mat-stroked-button class="mat-button-base mat-primary w-100" color="main"
      (click)="openLink(detalheCesta.UrlPdfProduto)"
          >Lâmina técnica
        </button>
  </div>
    <div class="transaction-table" *ngIf="tokensCompradosDetalhes">
      <div class="transaction-row">
        <div class="transaction-column">Nome</div>
        <div class="transaction-column">
          {{ tokensCompradosDetalhes?.name_nft }}
        </div>
      </div>

      <div class="transaction-row"
        *ngIf="tokensCompradosDetalhes">
        <div class="transaction-column w-100">Id do token</div>
        <div class="transaction-column">
          <p class="text-ellipsis w-170">
            {{ tokensCompradosDetalhes?.token }}
          </p>
        </div>
      </div>

      <!-- <div class="transaction-row"
        *ngIf="tokensCompradosDetalhes">
        <div class="transaction-column">Quantidade de tokens totais</div>
        <div class="transaction-column">
          {{ tokensCompradosDetalhes?.disponivel }}
        </div>
      </div> -->

      <!-- <div class="transaction-row">
        <div class="transaction-column">Quantidade de cotas</div>
        <div class="transaction-column">
          {{ qtdToken }} {{qtdToken > '1' ? 'cotas' : 'cota'}}
        </div>
      </div> -->

      <!-- <div class="transaction-row" *ngIf="tokensCompradosDetalhes">
        <div class="transaction-column">Investimento por cota</div>
        <div class="transaction-column">
          {{ tokensCompradosDetalhes?.preco_inicial | currency }}
        </div>
      </div> -->

      <!-- <div class="transaction-row">
        <div class="transaction-column">Valor total das taxas</div>
        <div class="transaction-column">R$ 5,00</div>
      </div> -->

      <!-- <div class="transaction-row">
        <div class="transaction-column">Valor total da compra</div>
        <div class="transaction-column">
          {{ pedidoDetalhes?.valor_transacao | currency }}
        </div>
      </div> -->

      <!-- <div class="transaction-row">
        <div class="transaction-column">Operação</div>
        <div class="transaction-column">
          <span style="color: #139ce0">Investimento</span>
        </div>
      </div> -->

      <div class="transaction-row">
        <div class="transaction-column">Status da transação</div>
        <div class="transaction-column">
          <span>{{ pedidoStatusMap[pedidoDetalhes?.status] || "Pendente aprovação" }}</span>
        </div>
      </div>

      <div class="transaction-row">
        <div class="transaction-column">Duração do Projeto:</div>
        <div class="transaction-column">
          02 meses
        </div>
      </div>

      <!-- <div class="transaction-row">
        <div class="transaction-column">Data da transação</div>
        <div class="transaction-column">
          {{ pedidoDetalhes?.data_pedido_criacao | date : "dd/MM/YYYY" }}
        </div>
      </div>

      <div class="transaction-row">
        <div class="transaction-column">Hora da transação</div>
        <div class="transaction-column">
          {{ pedidoDetalhes?.data_pedido_criacao | date : "HH:mm" }}
        </div>
      </div> -->
    </div>
    <!-- //END .transaction-table -->
  </section>

  <mat-divider></mat-divider>

  <!-- <section class="transaction-section">
    <h2 class="transaction-title">Forma de pagamento</h2>

    <div class="transaction-table">
      <div class="transaction-row">
        <div class="transaction-column">Tipo</div>
        <div class="transaction-column">Cartão de Crédito</div>
      </div>

      <div class="transaction-row">
        <div class="transaction-column">Número</div>
        <div class="transaction-column">**** **** **** 0123</div>
      </div>

      <div class="transaction-row">
        <div class="transaction-column">Bandeira</div>
        <div class="transaction-column">Mastercard</div>
      </div>
    </div>
  </section>

  <mat-divider></mat-divider> -->

  <!-- <section class="transaction-section">
    <h2 class="transaction-title">Composição da cesta </h2>

    
    <div class="tokens-list">
      <ng-container *ngIf="filhosCesta">
        <div class="row flex-div-300">
          <div class="" *ngFor="let item of filhosCesta">
            <card-token-sold [token]="item" [dataVencimento]="item?.data_vencimento">
            </card-token-sold>
          </div>
        </div>

      </ng-container>
    </div>

    <div *ngIf="false" class="transaction-section-footer">
      <button class="btn-transfer" mat-raised-button color="primary" [disabled]="item.status !== 'Concluído'">
        <mat-icon fontIcon="import_export"></mat-icon>
        Vender Tokens
      </button>
    </div>
  </section> -->
</section>