import { NgxMaskDirective, NgxMaskPipe, provideNgxMask } from 'ngx-mask';
import { PrivacyModePipe } from 'src/app/_helpers/privacy-mode.pipe';
import { ReactiveFormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { NgModule } from '@angular/core';
import { CardPrecatorioComponent } from 'src/app/module-client/client-investment/card-precatorio/card-precatorio.component';
// import { MaterialModule } from './material.module';
import { MaterialModule } from 'src/app/modules/material/material.module';

import { RouterModule } from '@angular/router';
import { CardInvestmentTokenComponent } from 'src/app/module-client/client-investment-token/card-investment-token/card-investment-token.component';
import { PdfViewComponent } from '../components/pdf-view/pdf-view.component';
import { PdfViewerModule } from 'ng2-pdf-viewer';
import { NavbarComponent } from '../components/navbar/navbar.component';
import { FooterLpComponent } from '../components/footer-lp/footer-lp.component';
import { AcordFilterComponent } from '../components/acord-filter/acord-filter.component';
import { CardInfoComponent } from '../components/card-info/card-info.component';
import { CardIconComponent } from '../components/card-icon/card-icon.component';
import { CardProjectComponent } from '../components/card-project/card-project.component';
import { CardCustomComponent } from '../components/card-custom/card-custom.component';
import { CardSimpleComponent } from '../components/card-simple/card-simple.component';
import { TableDocumentsComponent } from '../components/table-documents/table-documents.component';
import { InputDragDropComponent } from 'src/app/custom-components/input-drag-drop/input-drag-drop.component';


@NgModule({
  declarations: [
    PrivacyModePipe,
    CardPrecatorioComponent,
    CardInvestmentTokenComponent,
    PdfViewComponent,
    NavbarComponent,
    FooterLpComponent,
    AcordFilterComponent,
    CardInfoComponent,
    CardIconComponent,
    CardProjectComponent,
    CardCustomComponent,
    CardSimpleComponent,
    TableDocumentsComponent,
    InputDragDropComponent
  ],
  imports: [
    CommonModule, 
    ReactiveFormsModule,
    NgxMaskDirective,
    NgxMaskPipe,
    MaterialModule,
    RouterModule,
    PdfViewerModule
  ],
  exports: [
    ReactiveFormsModule,
    PrivacyModePipe,
    NgxMaskDirective,
    NgxMaskPipe,
    CardPrecatorioComponent,
    CardInvestmentTokenComponent,
    PdfViewComponent,
    PdfViewerModule,
    NavbarComponent,
    FooterLpComponent,
    AcordFilterComponent,
    CardInfoComponent,
    CardIconComponent,
    CardProjectComponent,
    CardCustomComponent,
    CardSimpleComponent,
    TableDocumentsComponent,
    InputDragDropComponent
  ],
  providers: [provideNgxMask()],
})
export class SharedModule {}
