export const navItems = [
    // { label: 'Produtos', routerLink: '/client/investir' },
    // { label: 'Contas conectadas', routerLink: '/client/contas-conectadas' },
    // { label: 'Como investir', routerLink: '/como-comprar' },
    // { label: 'Quem somos', routerLink: '/quem-somos' },
    { label: 'Clientes',  routerLink: '/aloc-cliente' },
    { label: 'Setor',  routerLink: '/aloc-setor' },
    { label: 'Produto',  routerLink: '/aloc-produto' },
    { label: 'Pessoa',  routerLink: '/aloc-pessoa' },
    { label: 'Alocação', routerLink: '/aloc-alocacao' },
    { label: 'Atendimento', routerLink: '/client/atendimento' }
];
