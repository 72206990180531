import { FormGroup, FormBuilder, FormArray, Validators } from '@angular/forms';
import { Component } from '@angular/core';
import { NftService } from 'src/app/services/nft.service';
import { SelectInterface } from 'src/app/models/select.model';
import { AuthenticationService } from 'src/app/services/authentication.service';
import { UtilService } from 'src/app/_helpers/util.service';

@Component({
  selector: 'tab-contas-conectadas',
  templateUrl: './contas-conectadas.component.html',
  styleUrls: ['./contas-conectadas.component.scss'],
})
export class ContasConectadasComponent {

  protected pixAccounts: any;
  protected formPix!: FormGroup;
  protected userId: string;
  protected mask: string;

  protected pixTypes: SelectInterface[] = [
    { value: 'cpf', viewValue: 'CPF' },
    { value: 'email', viewValue: 'E-mail' },
    { value: 'celular', viewValue: 'Número de celular' },
    { value: 'aleatoria', viewValue: 'Chave aleatória' },
  ];

  constructor(
    private readonly authService: AuthenticationService,
    private readonly fb: FormBuilder,
    private nftService: NftService,
    private utilService: UtilService
  ) {
    this.getUserData();
    this.createForm();
  }

  ngOnInit(): void {
    this.getDataPix();
  }

  protected getUserData(): void {
    const userData = this.authService.getUserData();
    if (userData) this.userId = String(userData.id);
  }

  protected createForm(): void {
    this.formPix = this.fb.group({
      conta_pix_tipo: ['', Validators.required],
      conta_pix_chave: ['', Validators.required]
    });
  }

  protected getDataPix(): void {
    this.nftService.getContaMyPix(this.userId).subscribe({
      next: (response: any) => {
        this.pixAccounts = response.body[0];

        const pixData = {
          conta_pix_tipo: this.pixAccounts.conta_pix_tipo,
          conta_pix_chave: this.pixAccounts.conta_pix_chave
        };

        this.formPix.patchValue(pixData);
        if (this.pixAccounts.conta_pix_tipo === 'celular' || this.pixAccounts.conta_pix_tipo === 'cpf') this.setMask();
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  get buttonLabel(): string {
    return this.pixAccounts?.length === 0 ? 'Cadastrar chave' : 'Atualizar chave';
  }

  get isUpdating(): boolean {
    return this.pixAccounts?.length !== 0;
  }

  protected saveDataPix(isPut: boolean): void {
    const data = this.formPix.value;

    const payload: any = {
      id_user: this.userId,
      conta_pix_tipo: data.conta_pix_tipo,
      conta_pix_chave: data.conta_pix_chave,
    };

    if (isPut) payload.conta_pix_id = this.pixAccounts.conta_pix_id;

    const request$ = isPut
      ? this.nftService.putContaPix(payload)
      : this.nftService.postContaPix(payload);

    request$.subscribe({
      next: (response) => {
        this.utilService.floaterNotificationSuccess("Chave cadastrada com sucesso.");
      },
      error: (error) => {
        console.error(error);
      }
    });
  }

  protected setMask(): void {
    const tipoChave = this.formPix.get('conta_pix_tipo')?.value;

    if (tipoChave === 'email' || tipoChave === 'aleatoria') {
      this.mask = '';
      return;
    }

    const masks = {
      cpf: '000.000.000-00',
      celular: '(00) 00000-0000'
    };

    this.mask = masks[tipoChave] || '';
  }

  protected onKeyTypeChange(): void {
    this.formPix.get('conta_pix_chave')?.setValue('');
    this.setMask();
  }

}
