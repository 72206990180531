<article class="auth-content">
  <div class="auth-container">
    <div class="auth-logo">
      <img src="assets/images/aem_logo.png"
        width="200"
        alt="Logotipo da A&M" />
    </div>
    <span class="auth-icon">
      <img src="assets/emojis/locked.png"
        alt="Emoji de cadeado" />
    </span>
    <h1 class="auth-title">
      Recuperar senha
    </h1>
    <p class="auth-subtitle">
      Sua nova senha deve ser diferente da senha usada anteriormente
    </p>
    <div class="auth-form">
      <form [formGroup]="recoverPasswordForm"
        (ngSubmit)="recoverPassword()">
        <div class="auth-form-field">
          <mat-form-field appearance="outline">
            <input placeholder="Nova senha"
              matInput
              [type]="isVisible ? 'text' : 'password'"
              formControlName="user_pwd"
              name="user_pwd"
              autocomplete="off"
              required>
            <mat-icon matSuffix
              (click)="toggleVisibility()">
              <span>{{ isVisible ? 'visibility' : 'visibility_off'}}</span>
            </mat-icon>
            <mat-error *ngIf="recoverPasswordForm.get('user_pwd')?.hasError('required')">Campo obrigatório</mat-error>
          </mat-form-field>
        </div>

        <div class="auth-form-field">
          <mat-form-field appearance="outline">
            <input placeholder="Confirmar senha"
              matInput
              [type]="isVisible ? 'text' : 'password'"
              formControlName="user_pwd_confirma"
              name="user_pwd_confirma"
              autocomplete="off"
              pattern="{{ recoverPasswordForm.value.senha }}"
              required>
            <mat-icon matSuffix
              (click)="toggleVisibility()">
              <span>{{ isVisible ? 'visibility' : 'visibility_off'}}</span>
            </mat-icon>
            <mat-error *ngIf="recoverPasswordForm.get('user_pwd_confirma')?.hasError('required')">Campo
              obrigatório</mat-error>
            <mat-error *ngIf="recoverPasswordForm.get('user_pwd_confirma')?.hasError('confirmedValidator')">Senhas não
              são iguais.</mat-error>
          </mat-form-field>
        </div>

        <div [hidden]="!recoverPasswordForm.get('user_pwd')?.hasError('pattern')"
          class="auth-form-notification auth-form-notification--warning">
          <div class="auth-form-notification-container">
            <div class="auth-form-notification-icon">
              <mat-icon>warning</mat-icon>
            </div>
            <div class="auth-form-notification-text">
              <p>Sua senha precisa ter:</p>
              <ul>
                <li>Mínimo 9 e máximo 20 caracteres</li>
                <li>Letra(s) maiúscula(s) e letra(s) minúscula(s)</li>
                <li>Mínimo 1 caractere especial ex.: !&#64;#$*()</li>
                <li>Mínimo 1 número</li>
              </ul>
            </div>
          </div>
        </div>

        <div class="auth-form-actions">
          <button mat-flat-button
            class="btn-primary btn-large btn-fluid text-uppercase mt-15 mb-25">Atualizar senha</button>
        </div>
        <div class="auth-form-actions auth-form-actions--centered">
          <a class="btn-link-primary"
            color="primary"
            routerLink="/auth/login">
            <mat-icon class="btn-icon"
              color="primary"
              fontIcon="arrow_back_ios"></mat-icon>
            Voltar para acesso
          </a>
        </div>
      </form>
    </div>
  </div>
</article>