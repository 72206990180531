<div class="auth-container">
  <div class="auth-logo">
    <img src="assets/images/aem_logo.png"
      width="200"
      alt="Logotipo da MV Ativos" />
  </div>
  <span class="auth-icon">
    <img src="assets/emojis/envelope-with-arrow.png"
      alt="Emoji de envelope" />
  </span>
  <h1 class="auth-title">
    Enviamos um código para o seu e-mail
  </h1>
  <p class="auth-subtitle">
    Digite o código que você acabou de receber no seu
    e-mail para confirmar que é seu (verifique também a sua caixa de SPAM):
  </p>
  <div class="auth-form">
    <form [formGroup]="registerFormConfirmation"
      (ngSubmit)="confirmRegistration()"
      autocomplete="off">
      <div class="auth-form-field">
        <mat-form-field appearance="outline">
          <input placeholder="Código"
            matInput
            type="tel"
            mask="000000"
            formControlName="verification_code"
            name="verification_code"
            autocomplete="off"
            required>
          <mat-error *ngIf="registerFormConfirmation.get('verification_code')?.hasError('required')">Campo
            obrigatório</mat-error>
        </mat-form-field>
      </div>

      <div class="auth-form-actions">
        <button mat-flat-button
          class="btn-primary btn-large btn-fluid text-uppercase mt-15 mb-25"
          [disabled]="!registerFormConfirmation.valid">Confirmar</button>
      </div>
      <div class="auth-form-actions auth-form-actions--centered">
        <p><a class="btn-link-primary"
            color="primary"
            (click)="obterCodigoVerificacao()">Reenviar código</a></p>
      </div>
    </form>
  </div>
</div>