import { AbstractControl, ValidationErrors, ValidatorFn } from '@angular/forms';
import { CpfService } from 'src/app/services/cpf.service';

export function cpfValidator(service: CpfService): ValidatorFn {
    return (control: AbstractControl): ValidationErrors | null => {
        const cpf = control.value;
        if (!cpf) return null;

        return service.validateCpf(cpf) ? null : { cpfInvalid: true };
    };
}
