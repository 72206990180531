import { Component } from '@angular/core';

@Component({
  selector: 'app-aloc-produto',
  standalone: true,
  imports: [],
  templateUrl: './aloc-produto.component.html',
  styleUrl: './aloc-produto.component.scss'
})
export class AlocProdutoComponent {

}
