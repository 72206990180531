<div class="card border-0 h-100"
    [ngStyle]="{ 'background-color': 'var(--' + backgroundClass + ')', 'color': 'var(--' + textClass + ')' }"
    [ngClass]="{ 'card-small': small }">
    <div class="card-body d-flex flex-column justify-content-center align-items-center">
        <div class="icon d-flex justify-content-center align-items-center mb-2" [ngClass]="{ 'icon-small': small }">
            <mat-icon>{{ iconName }}</mat-icon>
        </div>
        <h6 class="card-title mb-1 text-center" [class.fw-bold]="dataDescription">{{ dataName }}</h6>
        <span *ngIf="dataDescription" class="text-muted text-center">{{ dataDescription }}</span>

    </div>
</div>