<article class="auth-content">
  <div class="auth-container">
    <div class="auth-logo">
      <img src="assets/images/aem_logo.png" width="200" alt="Logotipo da MV Ativos" />
    </div>
    <span class="auth-icon">
      <img src="assets/emojis/rocket.png" alt="Emoji de foguete" />
    </span>
    <h1 class="auth-title">
      Invista em ativos e amplie seu patrimônio com segurança.
    </h1>
    <p class="auth-subtitle">
      
    </p>
    <div class="auth-form">
        <form [formGroup]="registerForm"
          (ngSubmit)="register()"
          autocomplete="off">
          <div class="auth-form-field">
            <mat-form-field appearance="outline">
              <input placeholder="Nome completo"
                matInput
                type="text"
                formControlName="user_name"
                name="user_name"
                autocomplete="off"
                required />
              <mat-error *ngIf="registerForm.get('user_name')?.hasError('required')">Campo obrigatório</mat-error>
            </mat-form-field>
          </div>

          <div class="auth-form-field">
            <mat-form-field appearance="outline">
              <input placeholder="CPF"
                matInput
                type="tel"
                formControlName="user_cpf_cnpj"
                name="user_cpf_cnpj"
                autocomplete="off"
                mask="000.000.000-00"
                required />
              <mat-error *ngIf="registerForm.get('user_cpf_cnpj')?.hasError('required')">Campo obrigatório</mat-error>
              <mat-error *ngIf="registerForm.get('user_cpf_cnpj')?.hasError('cpfInvalid')">
                CPF inválido
              </mat-error>
            </mat-form-field>
          </div>

          <div class="auth-form-field">
            <mat-form-field appearance="outline">
              <input placeholder="E-mail"
                matInput
                type="email"
                formControlName="user_email"
                name="user_email"
                autocomplete="off"
                required />
              <mat-error *ngIf="registerForm.get('user_email')?.hasError('required')">Campo obrigatório</mat-error>
              <mat-error *ngIf="registerForm.get('user_email')?.hasError('email')">E-mail inválido</mat-error>
            </mat-form-field>
          </div>

          <div class="auth-form-field">
            <mat-form-field appearance="outline">
              <input placeholder="Celular"
                matInput
                type="tel"
                formControlName="user_celular"
                name="user_celular"
                autocomplete="off"
                mask="(00) 00000-0000"
                required />
              <mat-error *ngIf="registerForm.get('user_celular')?.hasError('required')">Campo obrigatório</mat-error>
            </mat-form-field>
          </div>

          <div class="auth-form-field">
            <mat-form-field appearance="outline">
              <input placeholder="Senha"
                matInput
                [type]="isVisible ? 'text' : 'password'"
                formControlName="user_pwd"
                name="user_pwd"
                autocomplete="off"
                minlength="9"
                required />
              <mat-icon matSuffix
                (click)="toggleVisibility()">
                <span>{{ isVisible ? "visibility" : "visibility_off" }}</span>
              </mat-icon>
              <mat-error *ngIf="registerForm.get('user_pwd')?.hasError('required')">Campo obrigatório</mat-error>
              <mat-error *ngIf="registerForm.get('user_pwd')?.hasError('minlength')">Deve ter pelo menos 9
                caracteres</mat-error>
              <mat-error *ngIf="registerForm.get('user_pwd')?.hasError('maxlength')">Dete ter no máximo 20
                caracteres</mat-error>
            </mat-form-field>
          </div>

          <div class="auth-form-field">
            <mat-form-field appearance="outline">
              <input placeholder="Confirmar senha"
                matInput
                [type]="isVisible ? 'text' : 'password'"
                formControlName="user_pwd_confirma"
                name="user_pwd_confirma"
                autocomplete="off"
                minlength="9"
                required />
              <mat-icon matSuffix
                (click)="toggleVisibility()">
                <span>{{ isVisible ? "visibility" : "visibility_off" }}</span>
              </mat-icon>
              <mat-error *ngIf="
                  registerForm.get('user_pwd_confirma')?.hasError('required')
                ">Campo obrigatório</mat-error>
              <mat-error *ngIf="
                  registerForm
                    .get('user_pwd_confirma')
                    ?.hasError('confirmedValidator')
                ">Senhas não são iguais.</mat-error>
            </mat-form-field>
          </div>

          <div [hidden]="!registerForm.get('user_pwd')?.hasError('pattern')"
            class="auth-form-notification auth-form-notification--warning">
            <div class="auth-form-notification-container">
              <div class="auth-form-notification-icon">
                <mat-icon>warning</mat-icon>
              </div>
              <div class="auth-form-notification-text">
                <p>Sua senha precisa ter:</p>
                <ul>
                  <li>Mínimo 9 e máximo 20 caracteres</li>
                  <li>Letra(s) maiúscula(s) e letra(s) minúscula(s)</li>
                  <li>Mínimo 1 caractere especial ex.: !&#64;#$*()</li>
                  <li>Mínimo 1 número</li>
                </ul>
              </div>
            </div>
          </div>

          <div class="auth-form-actions">
            <button mat-flat-button
              class="btn-primary btn-large btn-fluid text-uppercase mt-15 mb-25">
              Cadastrar
            </button>
          </div>
          <div class="auth-form-actions auth-form-actions--centered">
            <p>
              Já tem uma conta? &nbsp;
              <a class="btn-link-primary"
                color="primary"
                routerLink="/auth/login">Acessar agora</a>
            </p>
          </div>
        </form>

    </div>
  </div>
</article>