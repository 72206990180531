import { DEFAULT_CURRENCY_CODE, LOCALE_ID, NgModule } from '@angular/core';
import { DateAdapter, NativeDateAdapter } from '@angular/material/core';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { HTTP_INTERCEPTORS, provideHttpClient, withInterceptorsFromDi } from '@angular/common/http';

import { AppComponent } from './app.component';
import { AppRoutingModule } from './app-routing.module';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { BrowserModule } from '@angular/platform-browser';

import { LoaderComponent } from './shared/components/page-not-found/loader/loader.component';

import { MatPaginatorIntl } from '@angular/material/paginator';
import { MaterialModule } from './modules/material/material.module';
import { ModuleAdminComponent } from './module-admin/module-admin.component';
import { ModuleAuthComponent } from './module-auth/module-auth.component';
import { ModuleClientComponent } from './module-client/module-client.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { ModuleLandingPageComponent } from './module-landing-page/module-landing-page.component';

import localePt from '@angular/common/locales/pt';
import { provideEnvironmentNgxMask } from 'ngx-mask';
import { registerLocaleData } from '@angular/common';
import { environment } from 'src/environments/environment';

import { PtBrMatPaginatorIntl } from './_helpers/pt-br-mat-paginator-intl';
import { LoaderInterceptor } from './_helpers/loader.interceptor';
import { ErrorInterceptor } from './_helpers/error.interceptor';
import { JwtInterceptor } from './_helpers/jwt.interceptor';
import { PdfViewComponent } from './shared/components/pdf-view/pdf-view.component';
import { NavbarComponent } from './shared/components/navbar/navbar.component';
import { SharedModule } from './shared/modules/shared.module';



registerLocaleData(localePt);
@NgModule({ declarations: [
        AppComponent,
        ModuleAuthComponent,
        ModuleAdminComponent,
        ModuleClientComponent,
        ModuleLandingPageComponent,
        // PageNotFoundComponent,
        LoaderComponent,
        // NavbarComponent
    ],
    bootstrap: [AppComponent], imports: [SharedModule,
        BrowserModule,
        BrowserAnimationsModule,
        FormsModule,
        ReactiveFormsModule,
        MaterialModule,
        AppRoutingModule,
        SharedModule], providers: [
        { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
        { provide: HTTP_INTERCEPTORS, useClass: LoaderInterceptor, multi: true },
        { provide: DateAdapter, useClass: NativeDateAdapter },
        { provide: LOCALE_ID, useValue: 'pt-BR' },
        { provide: DEFAULT_CURRENCY_CODE, useValue: 'BRL' },
        { provide: MatPaginatorIntl, useClass: PtBrMatPaginatorIntl },
        provideEnvironmentNgxMask(),
        provideHttpClient(withInterceptorsFromDi()),
    ] })
export class AppModule {
  // constructor(private configService: ConfigurationService) {
  //   this.configService.setEnvironment(environment);
  // }
}