<div class="auth-container">
  <div class="auth-logo">

  </div>

  <div *ngIf="hasSuccessfullyRegistered"
    class="auth-form-notification auth-form-notification--success">
    <div class="auth-form-notification-container">
      <div class="auth-form-notification-icon">
        <mat-icon>check_circle</mat-icon>
      </div>
      <div class="auth-form-notification-text">
        <p>
          Cadastro efetivado com sucesso! Agora faça seu primeiro acesso à
          sua conta.
        </p>
      </div>
    </div>
  </div>

  <div *ngIf="hasPasswordChanged"
    class="auth-form-notification auth-form-notification--success">
    <div class="auth-form-notification-container">
      <div class="auth-form-notification-icon">
        <mat-icon>check_circle</mat-icon>
      </div>
      <div class="auth-form-notification-text">
        <p>
          Senha alterada com sucesso. Você já pode acessar com a sua nova
          senha.
        </p>
      </div>
    </div>
  </div>
  <div style="display: flex; width:100%; justify-content: center;">
    <img style
      src="assets/images/aem_logo.png"
      width="200"
      alt="Logotipo da A&M" />
  </div>
  <br>
  <h1 class="auth-title"
    style="text-align: center;">
    Seja bem vindo(a) à plataforma A&M
  </h1>
  <p class="auth-subtitle"style="text-align: center;">
    Invista em ativos e amplie seu patrimônio com segurança.
  </p>
  <div class="auth-form">
    <form [formGroup]="loginForm"
      (ngSubmit)="loginFormSubmit()"
      autocomplete="off">
      <div class="auth-form-field">
        <mat-form-field appearance="outline">
          <input placeholder="E-mail"
            matInput
            type="email"
            formControlName="user_email"
            name="user_email"
            autocomplete="off"
            required />
          <mat-error *ngIf="loginForm.get('user_email')?.hasError('required')">Campo obrigatório</mat-error>
          <mat-error *ngIf="loginForm.get('user_email')?.hasError('email')">E-mail inválido</mat-error>
        </mat-form-field>
      </div>
      <div class="auth-form-field">
        <mat-form-field appearance="outline">
          <input placeholder="Senha"
            matInput
            [type]="isVisible ? 'text' : 'password'"
            formControlName="user_pwd"
            name="user_pwd"
            autocomplete="off"
            required />
          <mat-icon matSuffix
            (click)="toggleVisibility()">
            <span>{{ isVisible ? "visibility" : "visibility_off" }}</span>
          </mat-icon>
          <mat-error *ngIf="loginForm.get('user_pwd')?.hasError('required')">Campo obrigatório</mat-error>
        </mat-form-field>
      </div>
      <div class="auth-form-actions">
        <div class="auth-form-action-item">
          <a class="btn-link-primary"
            routerLink="/auth/esqueci-minha-senha">Esqueci minha senha</a>
        </div>
      </div>
      <div class="auth-form-actions">
        <button mat-flat-button
          class="btn-primary btn-large btn-fluid text-uppercase mt-15 mb-25"
          type="submit">
          Acessar
        </button>
      </div>
      <div class="auth-form-actions auth-form-actions--centered">
        <p>
          Novo aqui? &nbsp;
          <a class="btn-link-primary"
            color="primary"
            routerLink="/auth/cadastro">Cadastre-se</a>
        </p>
      </div>
    </form>
  </div>
</div>