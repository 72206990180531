import { RouterModule, Routes } from '@angular/router';

import { AuthGuard } from 'src/app/_helpers/auth.guard';
import { NgModule } from '@angular/core';
import { ModuleAdminComponent } from './module-admin/module-admin.component';
import { ModuleAuthComponent } from './module-auth/module-auth.component';
import { ModuleClientComponent } from './module-client/module-client.component';
import { PageNotFoundComponent } from './shared/components/page-not-found/page-not-found.component';
import { ModuleLandingPageComponent } from './module-landing-page/module-landing-page.component';
import {AlocAlocacaoComponent } from './aloc-alocacao/aloc-alocacao.component'
import { AlocClienteComponent } from './aloc-cliente/aloc-cliente.component'
import { AlocPessoaComponent } from './aloc-pessoa/aloc-pessoa.component'
import { AlocProdutoComponent } from './aloc-produto/aloc-produto.component'
import { AlocSetorComponent } from './aloc-setor/aloc-setor.component'

const routes: Routes = [
  // { path: '', redirectTo: 'produtos', pathMatch: 'full' },
  {
    path: 'aloc-alocacao',
    component: AlocAlocacaoComponent,
    canActivate: [AuthGuard],
    data: { title: 'Alocacao', menuIcon: 'token' },
  },
  {
    path: 'aloc-cliente',
    component: AlocClienteComponent,
    canActivate: [AuthGuard],
    data: { title: 'Clientes', menuIcon: 'token' },
  },
  {
    path: 'aloc-pessoa',
    component: AlocPessoaComponent,
    canActivate: [AuthGuard],
    data: { title: 'Pessoa', menuIcon: 'token' },
  },
  {
    path: 'aloc-setor',
    component: AlocSetorComponent,
    canActivate: [AuthGuard],
    data: { title: 'Setor', menuIcon: 'token' },
  },
  {
    path: 'aloc-produto',
    component: AlocProdutoComponent,
    canActivate: [AuthGuard],
    data: { title: 'Produto', menuIcon: 'token' },
  },    

  { path: '', redirectTo: 'client/atendimento', pathMatch: 'full' },
  {
    path: 'auth',
    component: ModuleAuthComponent,
    loadChildren: () =>
      import('./module-auth/module-auth.module').then(
        (m) => m.ModuleAuthModule
      ),
  },
  {
    path: 'admin',
    component: ModuleAdminComponent,
    canActivate: [AuthGuard],
    loadChildren: () =>
      import('./module-admin/module-admin.module').then(
        (m) => m.ModuleAdminModule
      ),
  },
  {
    path: 'client',
    component: ModuleClientComponent,
    // canActivate: [AuthGuard],
    loadChildren: () =>
      import('./module-client/module-client.module').then(
        (m) => m.ModuleClientModule
      ),
  },
  {
    path: '',
    component: ModuleLandingPageComponent,
    loadChildren: () =>
      import('./module-landing-page/module-landing-page.module').then(
        (m) => m.ModuleLandingPageModule
      ),
  },
  { path: '404', component: PageNotFoundComponent },

  { path: '**', redirectTo: '404' },
];

@NgModule({
  imports: [RouterModule.forRoot(routes, {
    scrollPositionRestoration: 'enabled'
  })],
  exports: [RouterModule],
})
export class AppRoutingModule {}
