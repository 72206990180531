<footer class="py-5 text-bg-dark fs-6">
    <div class="container">
        <div class="row">
            <div class="col-md-6">
                <img src="assets/images/aem_logo.png" width="100px" alt="">
                <p class="mt-4">Copyright © {{ currentYear }} - Todos os direitos reservados.</p>
                <p class="mt-4"><a href="https://www.aidda.io/" target="_blank" > powered by AIDDA</a></p>
                
            </div>
            <!-- <div class="col-md-6">
                <small>A sociedade empresária de pequeno porte e a oferta apresentada nesta plataforma estão automaticamente
                    dispensados de registro pela Comissão de Valores Mobiliários - CVM. A CVM não analisa previamente as
                    ofertas. As ofertas realizadas não implicam por parte da CVM a garantia da veracidade das
                    informações prestadas, de adequação à legislação vigente ou julgamento sobre a qualidade da
                    sociedade empresária de pequeno porte. Antes de aceitar uma oferta leia com atenção as informações
                    essenciais da oferta, em especial a seção de alertas sobre riscos.</small>
            </div> -->
        </div>
    </div>
</footer>