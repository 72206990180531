<nav class="bg-white fixed-top py-3">
    <div class="container d-flex justify-content-between align-items-center">
        <h1 class="fs-4" style="margin:0px">
            <img src="assets/images/aem_logo2.png" width="36px" alt="Logotipo da MV Ativos">
        </h1>

        <div class="d-flex gap-4 align-items-center d-none d-xl-flex">
            <a *ngFor="let link of navLinks; let i = index" [routerLink]="link.routerLink"
            class="nav-link"  [ngClass]="{ 'me-0': i === navLinks.length - 1 }">{{ link.label }}</a>
        </div>
        
        <div class="d-flex gap-3 align-items-center">
            <!-- <div class="d-flex gap-4 align-items-center d-none d-xl-flex">
                <a *ngFor="let link of navLinks; let i = index" [routerLink]="link.routerLink"
                class="nav-link"  [ngClass]="{ 'me-0': i === navLinks.length - 1 }">{{ link.label }}</a>
            </div> -->
            
            <div *ngIf="isLoggedIn" class="d-flex gap-3 align-items-center">
                <p class="text-main user-data mb-0">Olá, 
                    <a class="text-decoration-none fw-bold" [routerLink]="'/client/meus-dados'" aria-label="Acessa Meus Dados">
                        <span class="text-alternative-dark">{{ firstNameUser }}</span>
                    </a>
                </p>
                <a href="" (click)="logout($event)" class="text-main text-decoration-none logout">Sair</a>
            </div>
            
            <button *ngIf="!isLoggedIn" mat-raised-button class="mat-raised-button mat-button-base mat-primary" color="main" (click)="goTo('/auth/login')">Entrar</button>
            <button *ngIf="isLoggedIn" mat-raised-button class="mat-raised-button mat-button-base mat-primary" color="main" (click)="goTo('/client/carteira')">Minha Conta</button>
            
            <button class="hamburger" (click)="toggleMenu()">
                &#9776;
            </button>
        </div>
    </div>

    <div class="mobile-menu" *ngIf="isMenuOpen">
        <a *ngFor="let link of navLinks" [routerLink]="link.routerLink" class="nav-link">{{ link.label }}</a>
        <a *ngIf="isMenuOpen && isLoggedIn" href="" (click)="logout($event)" class="text-main text-decoration-none">Sair</a>
    </div>
</nav>
