import { Component } from '@angular/core';

@Component({
  selector: 'app-aloc-setor',
  standalone: true,
  imports: [],
  templateUrl: './aloc-setor.component.html',
  styleUrl: './aloc-setor.component.scss'
})
export class AlocSetorComponent {

}
