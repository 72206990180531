<section class="wallet-home-transactions container">
  <h1 class="text-main">Carteira</h1>
  <section class="app-content-wrapper">
    <mat-tab-group mat-stretch-tabs="false" mat-align-tabs="start" animationDuration="200ms">
      <!-- <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>account_balance_wallet</mat-icon>
          <span>Carteira</span>
        </ng-template>
        <div class="tab-body" mat-tab-body>
          <tab-carteira></tab-carteira>
        </div>
      </mat-tab> -->

      <!-- <mat-tab>
      <ng-template mat-tab-label>
        <mat-icon>monetization_on</mat-icon>
        <span>Contas Conectadas </span>
      </ng-template>
      <div class="tab-body" mat-tab-body>
        <tab-contas-conectadas></tab-contas-conectadas>
      </div>
    </mat-tab> -->
      <mat-tab>
        <ng-template mat-tab-label>
          <mat-icon>dashboard</mat-icon>
          <span>Projetos</span>
        </ng-template>
        <div class="tab-body py-5" mat-tab-body>
          <!-- Table -->
          <div class="transactions-content">
            <table class="table-default" mat-table [dataSource]="dataSourceProjects" matSort
              matSortActive="data_pedido_criacao" matSortDirection="desc">
              <!-- Coluna Data/Hora -->
              <ng-container matColumnDef="data_pedido_criacao">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Data/Hora</th>
                <td mat-cell *matCellDef="let item" data-label="Data/Hora">
                  {{ item.data_pedido_criacao | date : "dd/MM/YYYY HH:mm" }}
                  <!-- <a class="btn-link" [routerLink]="'/client/transacao-detalhe/' + item.id_pedido">
                    {{ item.data_pedido_criacao | date : "dd/MM/YYYY HH:mm" }}
                  </a> -->
                </td>
              </ng-container>

              <!-- Coluna Cliente -->
              <ng-container matColumnDef="cliente">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Cliente</th>
                <td mat-cell *matCellDef="let item" data-label="Cliente">
                  <strong>
                    {{ item.cliente }}
                    <!-- <mat-icon>monetization_on</mat-icon> -->
                  </strong>
                </td>
              </ng-container>

              <!-- Coluna Projeto -->
              <ng-container matColumnDef="projeto">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Projeto</th>
                <td mat-cell *matCellDef="let item" data-label="Projeto">
                  <span>
                    {{ item.projeto }}
                  </span>
                </td>
              </ng-container>

              <!-- Coluna Status -->
              <ng-container matColumnDef="status">
                <th mat-header-cell *matHeaderCellDef mat-sort-header>Status</th>
                <td mat-cell *matCellDef="let item" data-label="Status">
                  <span class="row-status" [class.row-status--warning]="item.status === 'pending'"
                    [class.row-status--error]="item.status === 'expired'">
                    <span [routerLink]="'/client/transacao-detalhe/' + item.id_pedido">
                      <ng-container [ngSwitch]="item.status">
                        <span *ngSwitchCase="'pending'">Em execução</span>
                        <span *ngSwitchCase="'expired'">Cancelado</span>
                        <span *ngSwitchCase="'approved'">Finalizado</span>
                      </ng-container>
                    </span>
                  </span>
                </td>
              </ng-container>

              <!-- Coluna Detalhes -->
              <ng-container matColumnDef="detalhes">
                <th mat-header-cell *matHeaderCellDef>Detalhes</th>
                <td mat-cell *matCellDef="let item" data-label="Detalhes">
                  <a class="btn-link" [routerLink]="'/client/transacao-detalhe/' + item.id_pedido">
                    <mat-icon> chevron_right </mat-icon>
                  </a>
                </td>
              </ng-container>

              <tr mat-header-row *matHeaderRowDef="projectsColunas"></tr>
              <tr mat-row *matRowDef="let item; columns: projectsColunas"></tr>
            </table>

            <mat-paginator class="paginator-default" [pageSizeOptions]="[10, 25, 50, 100]" showFirstLastButtons
              aria-label="Select page of periodic elements">
            </mat-paginator>
          </div>
        </div>
      </mat-tab>
    </mat-tab-group>
  </section>
</section>