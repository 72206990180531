import { Component, Input, ViewChild } from '@angular/core';
import { MatPaginator } from '@angular/material/paginator';
import { MatSort } from '@angular/material/sort';
import { MatTableDataSource } from '@angular/material/table';

@Component({
  selector: 'app-table-documents',
  standalone: false,
  templateUrl: './table-documents.component.html',
  styleUrl: './table-documents.component.scss'
})
export class TableDocumentsComponent {

  dataSource: MatTableDataSource<any> = new MatTableDataSource();

  @ViewChild(MatSort) sort!: MatSort;
  @ViewChild(MatPaginator) paginator!: MatPaginator;

  columns: string[] = [
    'nome',
    'origem',
    'chave',
    'detalhes'
  ];

  @Input() data: any;

  constructor() { }

  ngOnInit(): void {
    this.dataSource = new MatTableDataSource(this.data);
    setTimeout(() => this.pagination());
  }

  ngAfterViewInit(): void {
    this.dataSource.sort = this.sort;
  }

  private pagination(): void {
    this.dataSource.paginator = this.paginator;
    this.dataSource.sort = this.sort;
  }

}
