import { HttpEvent, HttpHandler, HttpInterceptor, HttpRequest } from '@angular/common/http';

import { Injectable } from '@angular/core';
import { LoaderService } from 'src/app/services/loader.service';
import { Observable } from 'rxjs';
import { finalize } from 'rxjs/operators';
import { environment } from 'src/environments/environment';

@Injectable()
export class LoaderInterceptor implements HttpInterceptor {
  constructor(private loaderService: LoaderService) {}
  intercept(
    request: HttpRequest<any>,
    next: HttpHandler
  ): Observable<HttpEvent<any>> {
    const API_URL = environment.apiUrl;
    const ignoreUrls = [API_URL+'marketplace_nft/saldo_hathor/'];

    if (ignoreUrls.some(url => request.url.includes(url))) {
      console.log('entrou aqui', ignoreUrls);
      return next.handle(request);
    }

    if (request.reportProgress === true) {
      // invertido para bloquear qdo quiser
      return next.handle(request);
    }
    this.loaderService.showLoader();
    return next
      .handle(request)
      .pipe(finalize(() => this.loaderService.hideLoader()));
  }
}
