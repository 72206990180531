import { Component } from '@angular/core';

@Component({
  selector: 'app-aloc-cliente',
  standalone: true,
  imports: [],
  templateUrl: './aloc-cliente.component.html',
  styleUrl: './aloc-cliente.component.scss'
})
export class AlocClienteComponent {

}
