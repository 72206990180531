import { UtilService } from 'src/app/_helpers/util.service';
import { MatIconRegistry } from '@angular/material/icon';
import { DomSanitizer } from '@angular/platform-browser';
import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';

import { NftService } from 'src/app/services/nft.service';
import { environment } from 'src/environments/environment';

@Component({
  selector: 'app-client-wallet-detail',
  templateUrl: './client-wallet-detail.component.html',
  styleUrls: ['./client-wallet-detail.component.scss'],
})
export class ClientWalletDetailComponent implements OnInit {
  protected item = {
    tipo_operacao: 'Compra',
    status: 'Pendente',
  };
  protected idPedido: string | any;
  protected idTransacao: string | null = null;
  protected pedidoDetalhes: any | null = null; // TODO: usar interface
  protected transacaoDetalhes: any | null = null; // TODO: usar interface
  protected filhosCesta: any | null = null; // TODO: usar interface
  protected pedidoStatusMap: any = {
    pending: 'Pendente',
    approved: 'Aprovado',
    expired: 'Expirado',
    cancelled: 'Cancelado',
    expirado: 'Expirado',
  };
  protected tokenId?: string;
  protected tokensCompradosDetalhes?: any = [];
  qtdToken: any;
  detalheCesta: any;

  constructor(
    private readonly matIconRegistry: MatIconRegistry,
    private readonly domSanitizer: DomSanitizer,
    private readonly route: ActivatedRoute,
    private readonly nftService: NftService,
    private readonly utilService: UtilService
  ) {
    this.matIconRegistry.addSvgIcon(
      'import_export',
      this.domSanitizer.bypassSecurityTrustResourceUrl(
        '../assets/icons/import_export.svg'
      )
    );

    this.idPedido = this.route.snapshot.params['id'];
    this.idTransacao = this.route.snapshot.params['id'];
  }

  ngOnInit(): void {
    if (this.idPedido) {
      this.getDadosPedido(this.idPedido);
      this.getFilhosCesta(this.idPedido);
      this.getDetalheCesta(this.idPedido);
    }
    // if (this.idTransacao) {
    //   this.getDadosTransacao(this.idTransacao);
    // }
  }
  
  /**
   * Gets dados pedido
   * @param idPedido
   */
  getDadosPedido(idPedido: string): void {
    this.nftService.getDadosPedido(idPedido).subscribe({
      next: (response: any) => {
        const data = response.body;
        if (data) {
          this.pedidoDetalhes = data;
          this.qtdToken = this.pedidoDetalhes.qtd_cesta;
          this.getDadosTransacao(this.idPedido)
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  getFilhosCesta(idPedido: any) {
    this.nftService.getProdutoByIdPedido(idPedido).subscribe({
      next: (response: any) => {
        this.filhosCesta = response.body;
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  getDetalheCesta(idPedido: any) {
    this.nftService.getCestaByIdPedido(idPedido).subscribe({
      next: (response: any) => {
        this.detalheCesta = response.body[0];
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  openLink(link: string) {
    console.log(link);
    window.open(link);
  }


  /**
   * Gets dados transacao
   * @param idTransacao
   */
  getDadosTransacao(idTransacao: string): void {
    this.nftService.getDetalhesItensPedidoTransacaoById(idTransacao).subscribe({
      next: (response: any) => {
        var that = this;
        const data = response.body;

        // @ts-ignore
        var countErros = response.body.filter(x => x.status_transacao == 'erro').length;
        // @ts-ignore
        var naoExecutado = response.body.filter(x => x.status_transacao == null).length;

        if (countErros > 0 || (that.pedidoDetalhes?.status == 'approved' && naoExecutado > 0)) {
          that.reprocessaPedido();
        }

        if (data) {
          this.transacaoDetalhes = data;
          this.tokenId = data[0].token;
          if (this.tokenId) {
            this.getTokenDetailById(this.tokenId);
          }
        }
      },
      error: (error) => {
        console.error(error);
      },
    });
  }

  protected getTokenDetailById(id: string): void {
    this.nftService.getNftDetalheByToken(id).subscribe({
      next: (response: any) => {
        if (response && response.body) {
          this.tokensCompradosDetalhes = response.body[0];
          this.transacaoDetalhes.pdf = this.getLinkOrigem(this.tokensCompradosDetalhes.ipfs_hash_file);
        }
      },
    });
  }

  getLinkOrigem(ipfsHashFile: string): string {
    return environment.gatewayIpfs + ipfsHashFile;
  }

  reprocessaPedido() {
    var that = this;
    if (this.idPedido) {
      this.nftService.getReprocessaPedido(this.idPedido).subscribe(
        function (json: any) {
          that.utilService.floaterNotification(json);
        }
      )
    }
  }
}
